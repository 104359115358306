import React from 'react';
import './cumpleaños.css';
import photo from "../../assets/images/GalleryPhotos/cumples.jpg";
import { Row, Col } from 'antd';

const Cumpleaños = () => {
  return (
    <Row className='cumpleaños' justify={'center'} align={'middle'} style={{ backgroundColor: '#F4F4F3' }}>
      <Col xs={0} sm={0} md={10} lg={8}>
        <div style={{ height: '100vh', padding: '2rem' }}>
          <img src={photo} height={'100%'} width={'100%'} alt="museo" />
        </div>
      </Col>
      <Col xs={24} sm={24} md={14} lg={16}>
        <div style={{ padding: '1rem' }}>
          <h2 style={{ fontWeight: 'bold' }}>Cumpleaños</h2>
          <strong>Días y horarios:</strong> Martes a viernes a las 13:00 o 17:00 hs. Sábados y domingos a las 13 hs.
          <br />
          <strong>Duración:</strong> 2:30 hs. Feriados no se festejan cumples.
          <p><strong>Cantidad mínima de invitados:</strong> 15 niños.</p>
          <p>
            <strong>Forma de reserva:</strong>  Las reservas se realizan con hasta dos meses de anticipación.
            <br />
            Pre-reserva por teléfono (4959-3700 int. 053129)
            con vigencia de 48 hs. Atención: martes a sábados de 13 a 19.30 hs.
          </p>
          <h2 style={{ fontWeight: 'bold' }}>Servicios incluidos:</h2>
          <ul>
            <li>2 guías animadores por cumple.</li>
            <li>Amplio salón compartido para la comida y para soplar las velitas.</li>
            <li>Tarjetas de invitación.</li>
            <li>Menú McDonald's para cada niño.</li>
            <li>Regalo para el cumpleañero.</li>
            <li>4 entradas gratuitas para el cumpleañero y su familia.</li>
            <li>Obsequio de 1 entrada gratuita para cada invitado.</li>
            <li>Estacionamiento gratis por 24 hs. para los papás del agasajado y de los invitados.</li>
            <li>Espacio para 8 adultos (Más adultos, ver Salón Exclusivo).</li>
          </ul>
          <strong >NO INCLUYE TORTA</strong>
          <hr />
          <p><strong>Opcional para adultos:</strong> Cafetería de McDonald's.</p>
          <p>
            <strong>Opcional salón exclusivo:</strong> Ubicado en la Biblioteca del Museo y ambientado especialmente. Acceso propio. Capacidad máxima para
            50 personas entre niños y adultos.
          </p>
          <p><strong>Para más información:</strong> <a href="mailto:cumples@museoabasto.org.ar">cumples@museoabasto.org.ar</a></p>
        </div>
      </Col>
    </Row>
  );
};

export default Cumpleaños;